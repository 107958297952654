
import { Storage } from '@capacitor/storage';

export const saveDownloadedPhotosToStorage = async (downloadedPhotos: string[]) => {
  try {
    await Storage.set({
      key: 'downloadedPhotos',
      value: JSON.stringify(downloadedPhotos),
    });
  } catch (error) {
    console.error('Error saving downloaded photos to storage:', error);
  }
};

export const loadDownloadedPhotosFromStorage = async () => {
  try {
    const photosString = await Storage.get({ key: 'downloadedPhotos' });
    if (photosString.value) {
      return JSON.parse(photosString.value);
    }
    return [];
  } catch (error) {
    console.error('Error loading downloaded photos from storage:', error);
    return [];
  }
};

export const removeFromDownloadedPhotosStorage = async (photoId: string) => {
  try {
    const currentPhotos = await loadDownloadedPhotosFromStorage();
    const updatedPhotos = currentPhotos.filter(photo => {
      const filenameWithoutExtension = photo.split('.')[0];
      return filenameWithoutExtension !== photoId;
    });
    await saveDownloadedPhotosToStorage(updatedPhotos);
  } catch (error) {
    console.error('Error removing photo from downloaded photos in storage:', error);
  }
};
