import {
  collection,
  CollectionReference,
  deleteDoc,
  deleteField,
  doc,
  DocumentData,
  DocumentReference,
  getDoc,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore'
import { getStorage, ref as storeRef, uploadBytes } from 'firebase/storage'
import { defineStore } from 'pinia'
import { CameraRoll, HydratedCameraRoll, HydratedPhoto, Photo } from 'types/types.d'
import { Photo as CapacitorPhoto } from '@capacitor/camera'
import { useGetUserDoc } from '~~/composables/useFirebase'
import { useUserStore } from './user'
import { v4 as uuidv4 } from 'uuid';
import exifr from 'exifr'
import { Unsubscribe } from '@firebase/util'
import { logEvent } from '~~/composables/useAnalytics'
import {
  loadDownloadedPhotosFromStorage,
  removeFromDownloadedPhotosStorage,
  saveDownloadedPhotosToStorage
} from "~/composables/useStorage";

export type RootState = {
  cameraRollsData: HydratedCameraRoll[]
  snapshotListenerUnsubscribe: null | Unsubscribe
}
// what is the difference between null and undefined?
// https://stackoverflow.com/questions/5076944/what-is-the-difference-between-null-and-undefined-in-javascript

const COLLECTION_NAME = 'camera-rolls'

const downloadedPhotos = ref<string[]>([]);

onMounted(async () => {
  downloadedPhotos.value = await loadDownloadedPhotosFromStorage();
});


export const useCameraRollsStore = defineStore({
  id: 'cameraRolls-store',
  state: () => {
    return {
      cameraRollsData: [] as HydratedCameraRoll[],
      snapshotListenerUnsubscribe: null,
      preparedPhoto: {
        format: null,
        blob: null,
        webPath: null,
        base64: null,
      },
    } as RootState
  },
  actions: {
    async reset() {
      if (this.snapshotListenerUnsubscribe) {
        this.snapshotListenerUnsubscribe()
      }
      this.$reset()
      this.setup()
    },
    userIsOwner(rollId: string) {
      const roll = this.cameraRollsData.find((r) => r.id == rollId)
      if (!roll) {
        return false
      }
      return roll.userIsOwner
    },
    async setup() {
      const userStore = useUserStore()
      if (this.cameraRollsData.length > 0) {
        return
      }

      const currentUser = await userStore.getCurrentUser()
      if (!currentUser) {
        console.debug('User is not logged in.')
        return
      }

      console.log('Filtering rolls using', currentUser.uid)
      const store = useNuxtApp().$db
      const collectionRef = collection(store, COLLECTION_NAME)
      const ref = doc(collection(store, 'users'), currentUser.uid)
      const q = query(collectionRef, where('members', 'array-contains', ref), orderBy('createdAt', 'desc'))

      // source: https://github.com/kazuooooo/pin.ia-plugin-firestore-sync/blob/master/src/index.ts#L38
      const unsubcribe = onSnapshot(q, async (qs) => {
        const datum = await Promise.all(
          qs.docs.map(async (d) => {
            const data = d.data()
            Object.defineProperty(data, 'id', {
              value: d.id,
              writable: false,
              enumerable: false,
            })

            data.userIsOwner = data.createdBy.path == ref!.path

            // Converts a Firebase date to a JavaScript Date
            if (data.createdAt) {
              data.createdAt = data.createdAt.toDate()
            }

            // Converts document references to Users with the data it refers to.
            data.createdBy = await useGetUserDoc(data.createdBy)

            data.members = await Promise.all(
              // only get data from non-deleted members
              data.members
                .filter((m: DocumentReference) => m)
                .map(async (m: DocumentReference) => {
                  return useGetUserDoc(m)
                })
            ).then(members => members.filter((m) => m?.uid))

            await Promise.all(
              Object.keys(data.photos).map(async (k: string) => {
                data.photos[k].createdBy = await useGetUserDoc(data.photos[k].createdBy)
                data.photos[k].createdAt = data.photos[k].createdAt.toDate()
              })
            )

            const sortedPhotos = Object.entries(data.photos).sort(
              (a, b) =>
                (data.photos[b[0]] as HydratedPhoto).createdAt.getTime() -
                (data.photos[a[0]] as HydratedPhoto).createdAt.getTime()
            )
            data.photos = [...sortedPhotos].reduce((obj: any, [key, val]) => {
              obj[key] = val
              return obj
            }, {})

            return data
          })
        )
        const userStore = useUserStore()
        userStore.addUnsusbscribeSnapshotListener(unsubcribe)

        this.$patch((state) => {
          state['cameraRollsData'] = datum as HydratedCameraRoll[]
          state['snapshotListenerUnsubscribe'] = unsubcribe
        })
      })
    },

    /**
     * Creates a camera roll and returns its id
     *
     * @return id string
     */
    async createCameraRoll(title: string, membersCanInvite: boolean): Promise<string> {
      const cameraRollCollection = createCollection<CameraRoll>(COLLECTION_NAME)
      const ref = doc(cameraRollCollection, uuidv4())
      const userStore = useUserStore()
      const userRef = userStore.userRef
      if (!userRef) {
        throw new Error('User is not logged in')
      }
      const cameraRoll: CameraRoll = {
        id: null,
        title,
        members: [userRef],
        membersCanInvite,
        createdBy: userRef,
        createdAt: serverTimestamp(),
        photos: {},
        closed: false,
      }
      await setDoc(ref, cameraRoll)
      logEvent('Host App usage', 'Created Gallery - true', ref.id)
      return ref.id
    },

    async updateCameraRoll(rollId: string, title: string, membersCanInvite: boolean, openCameraRoll: boolean) {
      const cameraRollCollection = createCollection<CameraRoll>(COLLECTION_NAME)
      const ref = doc(cameraRollCollection, rollId)
      console.debug(`Updating camera roll ${rollId}`)
      setDoc(
        ref,
        {
          title,
          membersCanInvite,
          closed: !openCameraRoll,
        },
        { merge: true }
      )
    },

    async deleteCameraRoll(rollId: string) {
      const cameraRollCollection = createCollection<CameraRoll>(COLLECTION_NAME)
      const ref = doc(cameraRollCollection, rollId)
      await deleteDoc(ref)
    },

    async blobToBase64(blob: Blob): Promise<string | ArrayBuffer | null>{
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        return new Promise((resolve) => {
          reader.onloadend = () => {
            resolve(reader.result)
          }
        })
    },

    async deletePhoto(rollId: string, photoId: string) {
      // find the camera roll
      const cameraRollCollection = createCollection<CameraRoll>(COLLECTION_NAME)
      const ref = doc(cameraRollCollection, rollId)
      await updateDoc(ref, {
        [`photos.${photoId}`]: deleteField(),
      })
      await removeFromDownloadedPhotosStorage(photoId)
      logEvent('Host App usage', 'Host Removed image file', rollId)
    },

    async storePhoto(cameraRollId: string, photo: CapacitorPhoto, blob: Blob, widthPx: number, heightPx: number, caption: string = '') {
      const fileId = uuidv4()
      const fileName = `${fileId}.${photo.format}`
      const fileType = `image/${photo.format}`
      const stRef = storeRef(getStorage(), `/photos/${fileName}`)
      const metadata = {
        cacheControl: 'max-age=5896800',
      }
      const getCreationTime = async () => {
        const exifData = await exifr.parse(blob as Blob, ['DateTimeOriginal'])
        if (exifData) {
          const d = exifData.DateTimeOriginal
          if (d instanceof Date) {
            console.debug(`Using exif data for creation time: ${d}`)
            return d
          }
        }
        // Fall back to current time
        return new Date()
      }

      const base64 = await this.blobToBase64(blob)
      const userStore = useUserStore()
      const creationTime = await getCreationTime()
      if (base64) {
        const memoryPhoto: Photo = {
          fileName: base64,
          fileType: 'blob',
          caption: caption,
          createdBy: userStore.userRef,
          createdAt: creationTime,
          widthPx: widthPx,
          heightPx: heightPx,
        }
        this.$patch((state) => {
          const cameraRoll = state['cameraRollsData'].find(item => item.id === cameraRollId);
          cameraRoll['photos'][fileId] = memoryPhoto
        })
      }

      const snapshot = await uploadBytes(stRef, blob as Blob, metadata)

      // This is just a helper to add the type to the db responses
      const createCollection = <T = DocumentData>(collectionName: string) => {
        return collection(getFirestore(), collectionName) as CollectionReference<T>
      }

      // Make an entry in the db
      const cameraRollCollection = createCollection<CameraRoll>(COLLECTION_NAME)
      const ref = doc(cameraRollCollection, cameraRollId)
      const docSnap = await getDoc(ref)

      const newPhoto: Photo = {
        fileName: fileName,
        fileType,
        caption: caption,
        createdBy: userStore.userRef,
        createdAt: creationTime,
        widthPx: widthPx,
        heightPx: heightPx,
      }

      let data: CameraRoll

      if (docSnap.exists()) {
        data = docSnap.data()
        data.photos[fileId] = newPhoto
        await setDoc(ref, data)
      } else {
        throw Error(`The collection ${cameraRollId} does not exist.`)
      }

      const userTypeName = this.userIsOwner(cameraRollId) ? 'Host' : 'Guest'

      logEvent(userTypeName + ' App usage', userTypeName + ' Added new image file', cameraRollId)

      if (caption) {
        logEvent(userTypeName + ' App usage', userTypeName + ' added comment', cameraRollId)
      }

      // Save photos to Storage
      downloadedPhotos.value.push(fileName);
      await saveDownloadedPhotosToStorage(downloadedPhotos.value);

      return snapshot.metadata.size
    },
  },
  getters: {},
})
